<!--
File:                   AddDrawingView.vue
Author:                 Vincent Audergon (https://github.com/AudergonV)
Description:            Add drawing view page
                        Allows to add a new drawing
Version:                1.0
Last modification date: 31.08.22
-->

<template>
  <div class="home">
    <h1>Nouveau dessin</h1>
    <v-file-input
        truncate-length="20"
        label="Fichier THR"
        accept=".thr"
        @change="OnFileOpened"
    ></v-file-input>
    <div v-if="svg">
        <h2>Options</h2>
        <v-text-field
            class="mt-4"
            v-model="name"
            label="Nom du dessin"
            clearable
          ></v-text-field>
        <v-checkbox
            v-model="eraseBefore"
            label="Toujours effacer avant"
            color="accent"
        ></v-checkbox>
        <v-slider
            label="Epaisseur du trait"
            max="3"
            min="0.05"
            step="0.05"
            v-model="strokeWidth"
            thumb-label="always"
            thumb-color="accent"
            >
    </v-slider>
        <h2>Aperçus</h2>
        <v-row>
            <v-col 
                cols="12"              
                xl="3"
                lg="3"
                md="6"
                sm="12"
                xs="12"
            > 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="black" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
            <v-col   
                cols="12"              
                xl="2"
                lg="2"
                md="4"
                sm="6"
                xs="6"> 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="black" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
            <v-col     
                cols="12"                          
                xl="1"
                lg="1"
                md="2"
                sm="3"
                xs="3"> 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="black" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
            <v-col 
                cols="12"              
                xl="3"
                lg="3"
                md="6"
                sm="12"
                xs="12"
            > 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="#adcab8" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
            <v-col   
                cols="12"                            
                xl="2"
                lg="2"
                md="4"
                sm="6"
                xs="6"> 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="#adcab8" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
            <v-col     
                cols="12"                          
                xl="1"
                lg="1"
                md="2"
                sm="3"
                xs="3"> 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="#adcab8" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
        </v-row>
        <img hidden id="thumbnail" width="350" height="350"/>
        <img hidden id="thumbnailActive" width="350" height="350"/>
        <canvas hidden id="mycanvas" width="350" height="350"></canvas>
        <!-- Device model -->
        <div>
            <h2 class="mt-5">Disponibilité</h2>
            <v-row style="flex-wrap: nowrap" class="overflow-x-auto">
                <v-col
                    cols="4" md="2"
                    v-for="model in models" :key="model.uuid"
                    >
                    <v-checkbox
                        v-model="model.isSelected"
                        :label="model.name"
                        color="accent"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </div>
        <!-- Buttons -->
        <v-btn class="mt-4" color="accent" @click="uploadDrawing">Ajouter au centre de téléchargement</v-btn>
        <v-btn class="mt-4 ml-4" color="primary" @click="getSunaeFile">Convertir vers un fichier Sunae</v-btn>
    </div>
    <v-overlay :value="loading">
    <v-progress-circular
        :size="70"
        :width="7"
        color="accent"
        indeterminate
        ></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import api from "@/api";
import router from "@/router";

export default {
    data () {
      return {
          svg:"",
          svgActive: "",
          strokeWidth: 0.2,
          eraseBefore: true,
          name:"",
          file: undefined,
          loading: false,
          models: []
      }
    },
    created() {
      this.loadData();
    },
    methods: {
        async loadData(){
            // Get models
            this.models = await api.getDeviceModels();
            for (let model of this.models) {
                model.isSelected = true;
            } 
        },
        OnFileOpened(file) {
            this.svg = ``;
            const reader = new FileReader();
            reader.onload = async (res) => {
                let content = res.target.result;
                this.name=file.name.split('.')[0];
                for (let line of content.split("\n")) {
                    if (!line.startsWith("#")){
                        let parts = line.split(" ");
                        if (parts.length == 2) {
                            this.svg += (100 + (Math.cos(Number(parts[0]) - Math.PI/2) * Number(parts[1])*100)).toFixed(2) + ',';
                            this.svg += (100 + (Math.sin(Number(parts[0]) - Math.PI/2) * Number(parts[1])*100)).toFixed(2) + ' '; 
                        }
                    }
               
                }
                console.log(this.svg);
            };
            reader.onerror = (err) => console.log(err);
            reader.readAsText(file);
            this.file = file;
        },
        async getSunaeFile() {
            this.loading = true;
            // TODO : fix this for Firefox
            let svg = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <polyline points="${this.svg}" fill="none" stroke="black" stroke-width="${this.strokeWidth}"/>
            </svg>`;
            let svgActive = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <polyline points="${this.svg}" fill="none" stroke="#adcab2" stroke-width="${this.strokeWidth}"/>
            </svg>`;
            let canvas = document.getElementById("mycanvas");
            let ctx = canvas.getContext('2d');
            let img = document.getElementById( "thumbnail" );
            let imgActive = document.getElementById( "thumbnailActive" );
            let png = "";
            let pngActive = "";
            await new Promise(resolve => {
                img.setAttribute( "src", "data:image/svg+xml;base64," +  btoa(svg)  );
                img.onload = async () => {
                    ctx.drawImage( img, 0, 0);
                    resolve(canvas.toDataURL("image/png"));
                };
            }).then(res => {
                png = res;
            });

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            await new Promise(resolve => {
                imgActive.setAttribute( "src", "data:image/svg+xml;base64," +  btoa(svgActive)  );
                imgActive.onload = async () => {
                    ctx.drawImage( imgActive, 0, 0);
                    resolve(canvas.toDataURL("image/png"));
                };
            }).then(res => {
                pngActive = res;
            });
            // Get models
            const disponibilities = [];
            for(const model of this.models){
                if(model.isSelected) disponibilities.push(model.uuid)
            }
            let result = await api.getSunaeFile(this.name, png, pngActive, this.eraseBefore, this.file, disponibilities);
            this.loading = false;
            const url = window.URL.createObjectURL(new Blob([JSON.stringify(result)]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'drawing.sunae')
            document.body.appendChild(link)
            link.click()
            router.push('/drawings');
        },
        async uploadDrawing() {
            this.loading = true;
            // TODO : fix this for Firefox
            let svg = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <polyline points="${this.svg}" fill="none" stroke="black" stroke-width="${this.strokeWidth}"/>
            </svg>`;
            let svgActive = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <polyline points="${this.svg}" fill="none" stroke="#adcab2" stroke-width="${this.strokeWidth}"/>
            </svg>`;
            let canvas = document.getElementById("mycanvas");
            let ctx = canvas.getContext('2d');
            let img = document.getElementById( "thumbnail" );
            let imgActive = document.getElementById( "thumbnailActive" );
            let png = "";
            let pngActive = "";
            await new Promise(resolve => {
                img.setAttribute( "src", "data:image/svg+xml;base64," +  btoa(svg)  );
                img.onload = async () => {
                    ctx.drawImage( img, 0, 0);
                    resolve(canvas.toDataURL("image/png"));
                };
            }).then(res => {
                png = res;
            });

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            await new Promise(resolve => {
                imgActive.setAttribute( "src", "data:image/svg+xml;base64," +  btoa(svgActive)  );
                imgActive.onload = async () => {
                    ctx.drawImage( imgActive, 0, 0);
                    resolve(canvas.toDataURL("image/png"));
                };
            }).then(res => {
                pngActive = res;
            });
            // Get models
            const disponibilities = [];
            for(const model of this.models){
                if(model.isSelected) disponibilities.push(model.uuid)
            }
            // Send request and return to previous page
            await api.uploadDrawing(this.name, svg, png, pngActive, this.eraseBefore, this.file, disponibilities);
            this.loading = false;
            router.push('/drawings');
        }
    }
}
</script>
