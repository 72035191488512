/****************************************************************************
 * @file index.js
 * @author Vincent Audergon <https://github.com/AudergonV>
 *
 * @brief Vue router
 *
 * @modifdate 31.08.22
 * @version 1.0.0
 ***************************************************************************/

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FirmwaresView from '../views/FirmwaresView.vue'
import DrawingsView from '../views/DrawingsView.vue'
import PlaylistsView from '../views/PlaylistsView.vue'
import AddDrawingView from '../views/AddDrawingView.vue'
import EditDrawingView from '../views/EditDrawingView.vue'
import LoginView from '../views/LoginView.vue'
import { requireAuth } from '@/auth';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/drawings',
    name: 'drawings',
    component: DrawingsView,
    beforeEnter: requireAuth
  },
  {
    path: '/drawings/new',
    name: 'addDrawing',
    component: AddDrawingView,
    beforeEnter: requireAuth
  },
  {
    path: '/drawings/edit/:uuid',
    name: 'editDrawing',
    component: EditDrawingView,
    beforeEnter: requireAuth
  },
  {
    path: '/playlists',
    name: 'playlists',
    component: PlaylistsView,
    beforeEnter: requireAuth
  },
  {
    path: '/firmwares',
    name: 'firmwares',
    component: FirmwaresView,
    beforeEnter: requireAuth
  }
]

const router = new VueRouter({
  routes
})

export default router
