<!--
File:                   FirmwaresView.vue
Author:                 Vincent Audergon (https://github.com/AudergonV)
Description:            Firmwares view page
                        Allows to publish / unpublish firmwares and gives a view of the available versions
Version:                1.0
Last modification date: 31.08.22
-->

<template>
  <div class="firmwares">

    <h1>Firmwares</h1>
    <v-tabs v-model="tab">
      <v-tab v-for="model in models"
            :key="model.uuid">{{model.name}}</v-tab>
    </v-tabs>
     <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="model in models"
        :key="model.uuid"
      >
            <v-data-table
              :headers="headers"
              :items="model.firmwares"
              :items-per-page="5"
              class="elevation-1"
            >
            <template v-slot:item.version="{ item }">
                {{formatVersion(item.version)}}
            </template>
            <template v-slot:item.tag="{ item }">
                <FirmwareTag :tag="item.tag"></FirmwareTag>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn icon :href="`https://management.sunae.ch/api.php/version/download?model=${model.uuid}&version=${item.version}`">
                    <v-icon color="primary">mdi-download</v-icon>
                </v-btn>
                <v-dialog v-if="!item.released"
                  v-model="item.publishDialog"
                  persistent
                  max-width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small  color="primary" outlined v-bind="attrs" v-on="on">
                    🌍 Publier
                    </v-btn> 
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      ⚠️ Avertissement
                    </v-card-title>
                    <v-card-text class="text-justify">Publier la <b><u>{{formatVersion(item.version)}}</u></b> du firmware pour le modèle <b><u>{{model.name}}</u></b>? Tous les clients auront accès à cette version, elle doit être <b>testée et parfaitement validée</b>. Il est <b><u><span style="color: red;">impératif</span></u></b> que la mise à jour OTA soit encore opérationnelle avec cette version.</v-card-text>
                    <v-card-actions>
                      <v-checkbox v-model="confirmPubilsh" label="J'ai lu l'avertissement"></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="success"
                        text
                        @click="item.publishDialog = false; confirmPubilsh = false;"
                      >
                        Annuler
                      </v-btn>
                      <v-btn :disabled="!confirmPubilsh"
                        color="error"
                        text
                        @click="publish(model, item)"
                      >
                        🌍 Publier
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-else
                  v-model="item.unpublishDialog"
                  persistent
                  max-width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                     <v-btn small  color="error" v-bind="attrs" v-on="on">
                    🔒 Masquer
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      ⚠️ Avertissement
                    </v-card-title>
                    <v-card-text class="text-justify">Masquer la <b><u>{{formatVersion(item.version)}}</u></b> du firmware pour le modèle <b><u>{{model.name}}</u></b>? La version ne sera plus accessible mais certains clients auront peut-être déjà mis à jour leur device avec.</v-card-text>
                    <v-card-actions>
                      <v-checkbox v-model="confirmUnpubilsh" label="J'ai lu l'avertissement"></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="success"
                        text
                        @click="item.unpublishDialog = false; confirmUnpubilsh = false;"
                      >
                        Annuler
                      </v-btn>
                      <v-btn :disabled="!confirmUnpubilsh"
                        color="error"
                        text
                        @click="unpublish(model, item)"
                      >
                        🔒 Masquer
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            </template>
            <template v-slot:item.released="{ item }">
                <v-chip v-if="item.released"
                  class="ma-2"
                  color="green"
                  outlined
                >
                  🚀 Publié
                </v-chip>
                <v-chip v-else
                  class="ma-2"
                  color="red"
                  outlined
                >
                  🔒 Non publié
                </v-chip>
                <v-chip v-if="item.version == model.latest"
                  class="ma-2"
                  color="blue"
                  outlined
                >
                  🛡️ Dernière version stable
                </v-chip>
            </template>
            <template v-slot:item.releaseDate="{ item }">
                {{item.releaseDate ? moment(item.releaseDate).format("DD/MM/YYYY") : '-'}}
            </template>
            <template v-slot:item.changelog="{ item }">
              <v-dialog
                  v-model="item.changelogDialog"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      outlined      
                      small              
                      >
                      <v-icon>mdi-checklist</v-icon>&nbsp;Voir le change log
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Change log {{formatVersion(item.version)}}
                    </v-card-title>

                    <v-card-text class="pt-5">
                      <pre>{{item.changelog}}</pre>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="item.changelogDialog = false"
                      >
                        Fermer
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            </template>
            </v-data-table>
        </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";
import FirmwareTag from "../components/FirmwareTag.vue";

  export default {
    data () {
      return {
        confirmPubilsh: false,
        confirmUnpubilsh: false,
        changelogDialog: false,
        publishDialog: false,
        tab: {},
        moment,
        headers: [
          { text: 'Version', value: 'version' },
          { text: 'Tag', value: 'tag'},
          { text: 'Change log', value: 'changelog', sortable: false},
          { text: 'Etat', value: 'released' },
          { text: 'Date de publication', value: 'releaseDate'},
          { text: 'Actions', value:'actions', sortable: false},
        ],
        models: [],
      }
    },
    components: {
      FirmwareTag
    },
    created() {
      this.loadData();
    },
    methods: {
      async loadData() {
        this.models = await api.getDeviceModels();
        for (let model of this.models) {
          model.firmwares = await api.getFirmwares(model.uuid);
          model.latest = await api.getLatestVersion(model.uuid);
        } 
      },
      formatVersion(version) {
        return `v${Math.floor(version/100)%10}.${Math.floor(version/10)%10}.${version%10}`;
      },
      async publish(model, firmware) {
        await api.publishFirmware(model.uuid, firmware.version);
        this.publishDialog = false;
        this.loadData();
      },
      async unpublish(model, firmware) {
        await api.unpublishFirmware(model.uuid, firmware.version);
        this.unpublishDialog = false;
        this.loadData();
      }
    }
  }
</script>