<!--
File:                   LoginView.vue
Author:                 Vincent Audergon (https://github.com/AudergonV)
Description:            Login view page, allows the admin to connect to the webapp
Version:                1.0
Last modification date: 31.08.22
-->

<template>
    <v-container>
        <!-- Connection form -->
        <v-layout align-center justify-center>
            <v-flex class="test-xs-center">
                <v-card dark>
                    <v-card-text>
                      <v-row align="center">
                        <v-col class="text-h2" cols=6><v-img contain src="../assets/logo.png" max-width="200"></v-img></v-col>
                      </v-row>
                      <h1 class="mt-5">Connexion</h1>
                      <v-form>
                      <v-text-field prepend-icon='mdi-account' label="Nom d'utilisateur" v-model="username"></v-text-field>
                      <v-text-field prepend-icon='mdi-key' @keydown="onKeydown" type="password" label='Mot de passe' v-model="password"></v-text-field>
                      <v-btn @click="_login()" color="accent">Login</v-btn>
                  </v-form>
                  </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>

        <!-- Error message in case of wrong password -->
        <v-alert v-if="error" type="error">
           {{error}}
        </v-alert>
    </v-container>
</template>

<script>
import api from "@/api";
import store from '@/store';
import {login} from "@/auth"

export default {
    data() {
        return {
            username: "",
            password: "",
            error: ""
        };
    },
    created() {
        store.commit("setConnected", false);
    },
    methods: {
        onKeydown(event){
            if (event.key == "Enter") {
                this._login();
            }
        },
        async _login() {
            let res = await api.login(this.username, this.password);
            this.password = "";
            res = JSON.parse(res);
            if (res.token) {
                this.username = "";
                login(res.token);
            } else {
                this.error = "Nom d'utilisateur ou mot de passe invalide";
            }
        }
    }
};
</script>