/****************************************************************************
 * @file index.js
 * @author Vincent Audergon <https://github.com/AudergonV>
 *
 * @brief API client
 *
 * @modifdate 31.08.22
 * @version 1.0.0
 ***************************************************************************/

import axios from 'axios';
import router from '@/router';
import { getAccessToken } from '@/auth';

const URL = "https://management.sunae.ch/api.php/"; 

const client = axios.create({
    baseURL: URL,
    json: true
});

export default {
    async execute(method, resource, data) {
        let accessToken = getAccessToken();
        return client({
            method,
            url: resource,
            data,
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then(req => {
            return req.data;
        }).catch(error => {
            console.log("error", error);
            if (error.response) {
                if (error.response.status === 404) {
                    router.push('/404');
                } else if (error.response.status === 403) {
                    router.push('/login').catch(() => {});
                }
                return error.response.data;
            } else {
                return 'Impossible d\'atteindre le serveur.';
            }
        });
    },
    getAllDrawings(from, to) {
        return this.execute('get', `drawing/all?from=${from}&to=${to}`);
    },
    getDrawing(uuid) {
        return this.execute('get', `drawing/svg?uuid=${uuid}`);
    },
    getDrawingPageCount() {
        return this.execute('get', `drawing/pages`);
    },
    getDeviceModels() {
        return this.execute('get', `version/all`);
    },
    getFirmwares(model) {
        return this.execute('get', `version/list?model=${model}`);
    },
    getLatestVersion(model) {
        return this.execute('get', `version/last?model=${model}`);
    },
    hideDrawing(uuid) {
        let bodyFormData = new FormData();
        bodyFormData.set('uuid', uuid);
        return this.execute('post', `drawing/hide`, bodyFormData);
    },
    publishFirmware(model, version) {
        let bodyFormData = new FormData();
        bodyFormData.append('model', model);
        bodyFormData.append('version', version);
        return this.execute('post', `version/publish`, bodyFormData);
    },
    unpublishFirmware(model, version) {
        let bodyFormData = new FormData();
        bodyFormData.append('model', model);
        bodyFormData.append('version', version);
        return this.execute('post', `version/unpublish`, bodyFormData);
    },
    uploadDrawing(name, svg, pngThumbnail, pngThumbnailActive, eraseBefore, thr, disponibilities) {
        let bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('svg', svg);
        bodyFormData.append('png', pngThumbnail);
        bodyFormData.append('pngActive', pngThumbnailActive);
        bodyFormData.append('eraseBefore', eraseBefore ? 1 : 0);
        bodyFormData.append('thr', thr);
        bodyFormData.append('disponibilities', JSON.stringify(disponibilities));
        return this.execute('post', 'drawing/add', bodyFormData);
    },
    getSunaeFile(name, pngThumbnail, pngThumbnailActive, eraseBefore, thr, modelid) {
        let bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('png', pngThumbnail);
        bodyFormData.append('pngActive', pngThumbnailActive);
        bodyFormData.append('eraseBefore', eraseBefore ? 1 : 0);
        bodyFormData.append('thr', thr);
        bodyFormData.append('modelid', modelid);
        return this.execute('post', 'drawing/thrToGcode', bodyFormData);
    },
    editDrawing(uuid, svg, name, eraseBefore, pngThumbnail, pngThumbnailActive, disponibilities) {
        let bodyFormData = new FormData();
        bodyFormData.append('uuid', uuid);
        bodyFormData.append('name', name);
        bodyFormData.append('svg', svg);
        bodyFormData.append('png', pngThumbnail);
        bodyFormData.append('pngActive', pngThumbnailActive);
        bodyFormData.append('eraseBefore', eraseBefore ? 1 : 0);
        bodyFormData.append('disponibilities', JSON.stringify(disponibilities));
        return this.execute('post', 'drawing/edit', bodyFormData);
    },
    updateDrawingPng(uuid, png, pngActive) {
        let bodyFormData = new FormData();
        bodyFormData.append('uuid', uuid);
        bodyFormData.append('png', png);
        bodyFormData.append('pngActive', pngActive);
        return this.execute('post', 'drawing/updatePng', bodyFormData);
    },
    exportDrawings(ids) {
        let bodyFormData = new FormData();
        let ids_param = "";
        for (let i = 0; i < ids.length; i++) {
            ids_param += ids[i];
            if (i < ids.length - 1) {
                ids_param += ",";
            }
        }
        bodyFormData.append('ids', ids_param);
        return this.execute('post', 'drawing/export', bodyFormData);
    },
    regenerateGcodes(ids) {
        let bodyFormData = new FormData();
        let ids_param = "";
        for (let i = 0; i < ids.length; i++) {
            ids_param += ids[i];
            if (i < ids.length - 1) {
                ids_param += ",";
            }
        }
        bodyFormData.append('ids', ids_param);
        return this.execute('post', 'drawing/updateGcode', bodyFormData);
    },
    patchDisponibilities(uuid, disponibilities) {
        return this.execute('patch', `drawing/disponibilities?uuid=${uuid}&disponibilities=${JSON.stringify(disponibilities)}`);
    },
    login(username, password) {
        let bodyFormData = new FormData();
        bodyFormData.append('username', username);
        bodyFormData.append('password', password);
        return this.execute('post', 'auth/login', bodyFormData);
    },
    logout() {
        return this.execute('post', 'auth/logout');
    },
    checkSession() {
        return this.execute('get', 'auth/check');
    }
}