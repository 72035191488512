import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import homeSvg from '../assets/Home.svg';
import drawingSvg from '../assets/drawing.svg';
import playlistSvg from '../assets/playlist.svg';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#000000",
            secondary: "#FFFFFF",
            third: "#7c878e", 
            accent: "#adcab8", 
          },
        },
      },
    icons: {
        iconfont: 'mdi',
        values: 
            {
                sunaehome: homeSvg,
                sunaedrawing: drawingSvg,
                sunaeplaylist: playlistSvg,
            },
        },
});
