<!--
File:                   App.vue
Author:                 Vincent Audergon (https://github.com/AudergonV)
Description:            Main vue file, contains the router-view component
                        and the navigation drawer
Version:                1.0
Last modification date: 31.08.22
-->

<template>
  <v-app>
    <!-- Navigation drawer -->
    <v-navigation-drawer
          v-if="connected"
          v-model="drawer"
          clipped
          app
          dark
        >

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              v-for="item in menu"
              :key="item.title"
              :to="item.href"
              link
            >
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list> 

    </v-navigation-drawer>
    <!-- App bar (top of the page) -->
    <v-app-bar
      color="primary"
      dark
      clipped-left
      app
    >
      <v-app-bar-nav-icon v-if="connected" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img
        alt="Logo"
        class="shrink"
        contain
        min-width="80"
        src="./assets/logo.png"
        width="120"
      />
      <v-spacer></v-spacer>
      <v-btn v-if="connected" @click="logout">Déconnexion</v-btn>
    </v-app-bar>
         
    <!-- Main content -->
    <v-main>
      <v-container  >
          <router-view />
      </v-container>
    </v-main>

    <!-- Footer -->
    <v-footer app dark>
      <div class="text-caption" id="copyright">&copy; Sunae {{moment().year()}}
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import moment from "moment";
import store from "@/store";
import router from "./router";
import {logout} from "@/auth";

export default {
  data () {
      return {
        moment,
        drawer: true,
        menu: [
        {
          title: "Accueil", 
          icon: "mdi-home", 
          href:"/"
        },
        {
          title: "Dessins",
          icon: "mdi-cards-outline",
          href: "/drawings"
        },
        {
          title: "Playlists",
          icon: "mdi-format-list-bulleted",
          href: "/playlists"
        },
        {
          title: "Firmwares",
          icon: "mdi-file",
          href: "/firmwares"
        }
      ]
      }
    },
    computed: {
      connected() {
        return store.getters.isConnected;
      }
    },
    watch: {
      async connected (newValue, oldValue) { 
        if (newValue && !oldValue) {
          //When we connect, we open the drawer automatically
          this.drawer = true;
        }
      },
    },
    methods: {
      logout() {
        logout();
        router.push('/login');
      }
    }
};
</script>

<style>
.swal2-container {
  font-family: "Helvetica"
}
</style>
